import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { HttpClientModule } from '@angular/common/http';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { ToastrModule } from 'ngx-toastr';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TouchlessQrsModule } from './_shared/touchless-qrs/touchless-qrs.module';
import { TimeOutPopupModule } from './_shared/time-out-popup/time-out-popup.module';
import { environment } from '../environments/environment';
import { GenericPopupModule } from './_shared/generic-popup/generic-popup.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    LoggerModule.forRoot({
      disableConsoleLogging: environment.production,
      level: NgxLoggerLevel.DEBUG,
    }),
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.name !== 'local',
      registrationStrategy: 'registerImmediately',
    }),
    TouchlessQrsModule,
    TimeOutPopupModule,
    GenericPopupModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
