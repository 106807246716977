import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NGXLogger } from "ngx-logger";
import { Subject } from "rxjs";
import { SetService } from "src/app/pages/set-panel/services/set.service";
import { TouchlessService } from "src/app/_services/touchless-service/touchless.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TimeOutPopupService {
  public openModalSubject = new Subject<boolean>();
  public currentTime: number = environment.timeOutSeconds;
  private _INITIALIZED: boolean = false;
  private _ENABLED: boolean = false;
  private _INTERVAL: number;
  private _COUNT_DOWN: number;
  constructor(
    private readonly router: Router,
    private readonly touchlessService: TouchlessService,
    private readonly Logger: NGXLogger,
    private setService: SetService
  ) { }
  private _subscribeTimeOut() {
    this.router.events.subscribe(this.resetCount.bind(this));
    this.touchlessService.onAction().subscribe(this.resetCount.bind(this));
    this.touchlessService.onData().subscribe(this.resetCount.bind(this));

    //TODO -> Better implementation with observable
    window.addEventListener(
      "keypress",
      () => document.querySelector("#modalBody") ?? this.resetCount()
    );
    window.addEventListener(
      "click",
      () => document.querySelector("#modalBody") ?? this.resetCount()
    );
  }
  private _onTimeOutDecreases() {
    this.currentTime--;
    if (environment.name === "local") {
      this.Logger.info(this.currentTime);
    }
    if (this.currentTime === 10) {
      this.openModalSubject.next(true);
    }
    if (this.currentTime <= 0) {
      this.touchlessService.endSession();
      window.location.reload();
    }
  }
  setVars(enabledStatus: boolean, countDown: number) {
    this._ENABLED = enabledStatus;
    this.currentTime = countDown;
    this._COUNT_DOWN = countDown;
  }
  initializeTimeOut() {
    if (this._INITIALIZED || !this._ENABLED) {
      return;
    }
    this._INITIALIZED = true;
    this._subscribeTimeOut();
    this._INTERVAL = window.setInterval(
      this._onTimeOutDecreases.bind(this),
      1000
    );
  }
  openModal() {
    this.openModalSubject.next(true);
  }
  resetCount() {
    setTimeout(() => {
      this.currentTime = this._COUNT_DOWN
        ? this._COUNT_DOWN
        : environment.timeOutSeconds;
    }, 500);
  }
  clearTimeOut() {
    clearInterval(this._INTERVAL);
    this._INITIALIZED = false;
  }
}
