import { Company } from '../company.model';
import { Content } from '../content.model';
import { SetPage } from './set-page';
import { SetHeader } from './set-header';
import { SetFooter } from './set-footer';
import { SetDetails } from './set-details';
import { SetConfiguration } from './set-configuration';
import { CartSystem } from './cart-system';
import { TimeOutService } from './time-out-service';
import { CustomerForm } from './customer-form';
import { DocumentList } from './document-list';
import { CardDataScanner } from './card-data-scanner';
import { TouchlessIntegration } from './touchless-integration';

export class Set {
  _id: string;
  name: string;
  company: Company;
  content: Content[];
  welcomePage: SetPage;
  header: SetHeader;
  footer: SetFooter;
  details?: SetDetails;
  config: SetConfiguration;
  cartSystem: CartSystem;
  timeOutService?: TimeOutService;
  customerForm: CustomerForm;
  documentList?: DocumentList;
  cardDataScanner: CardDataScanner;
  touchlessIntegration: TouchlessIntegration;
}
