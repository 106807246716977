import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { SetService } from '../pages/set-panel/services/set.service';
import { Qrs } from '../_type/qrs.interface';

@Injectable({
  providedIn: 'root'
})
export class QrCodeHandlerService {

  public startSessionQr: Qrs = {};
  public endSessionQr: Qrs = {};
  public isControllerConnected: boolean = false; /** Update when controller is connected (Load event is triggered from controller) */

  constructor(
    private readonly setService: SetService,
    private readonly Logger: NGXLogger,
  ) { }

  public initialize() {
    this.setService.actualSet.subscribe(
      (set) => {
        this.startSessionQr.show = set.touchlessIntegration.controllerQrCode.show;
        this.endSessionQr.show = set.touchlessIntegration.unlinkQrCode.show;
        this.startSessionQr.position = set.touchlessIntegration.controllerQrCode.position
        this.endSessionQr.position = set.touchlessIntegration.unlinkQrCode.position;
      }
    )
  }

  public async updateQrSrc(requestDataMethod: any) {

    const _startSrc = await requestDataMethod(
      { req: 'getQR-code', type: 'start-controller' },
    );

    const _endSrc = await requestDataMethod(
      { req: 'getQR-code', type: 'unlink-controller' },
    );

    this.startSessionQr.src = _startSrc;
    this.endSessionQr.src = _endSrc;
  }

}
