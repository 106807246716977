import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import _utils from '../_shared/_utils';
import { ChildHandshake, LocalHandle, WindowMessenger, RemoteHandle, Connection } from 'post-me';
import { environment } from 'src/environments/environment';
import { QrCodeHandlerService } from './qr-code-handler.service';
import { Subject } from 'rxjs';
import { TimeOutPopupService } from '../_shared/time-out-popup/_services/time-out-popup.service';
import { SetService } from '../pages/set-panel/services/set.service';

@Injectable({
  providedIn: 'root'
})
export class ApiPostMessageService {

  private localHandle: LocalHandle = undefined;
  private remoteHandle: RemoteHandle = undefined;
  public onActionSubject: Subject<any> = new Subject();
  public onDataSubject: Subject<any> = new Subject();
  private _connected = false;

  constructor(
    private readonly logger: NGXLogger,
    private readonly qrCodeHandlerService: QrCodeHandlerService,
    private readonly setService: SetService,
  ) { }

  async connect() {
    if (this._connected) { return; }
    this._connected = true;
    // Try to reach connection
    const messenger = new WindowMessenger({
      localWindow: window,
      remoteWindow: window.parent,
      remoteOrigin: environment.touchlessParentOrigin // Parent origin TBD
    });
    const connection = await ChildHandshake(
      messenger,
      _utils.apiPostMessageMethods(this._onAction.bind(this))
    );
    if (!connection) return this.logger.warn("Touchless connection error. Ignoring...");
    this.remoteHandle = connection.remoteHandle();
    this.localHandle = connection.localHandle();
    // If connection is stablish correctly, then handle qrs.
    this.qrCodeHandlerService.initialize();
    /**
     * get & update qrs
     */
    this.qrCodeHandlerService.updateQrSrc(
      this.requestData.bind(this)
    );
    this._listenEvent('data', this._onData);
  }

  // Send action to touchless front (end session i.e.)
  async sendAction(params: any) {
    if (!this.remoteHandle) return;
    return await this.remoteHandle.call('action', params);
  }

  // Only used to request qrs to touchless front
  async requestData(params: any, cb: any) {
    if (!this.remoteHandle) return;
    return await this.remoteHandle.call('data', params);
  }

  /**
   * Listen for events emitted from controller
   */
  private async _listenEvent(eventName: string, cb: (params: any) => void) {
    if (!this.remoteHandle) return;
    this.remoteHandle.addEventListener(eventName, cb.bind(this));
  }

  /**
   * Emit action to controller
   */
  async emitAction(params: any) {
    if (!this.localHandle) return;
    this.localHandle.emit('action', params);
  }

  /**
   * Emit data to controller
   */
  async emitData(params: any) {
    if (!this.localHandle) return;
    this.localHandle.emit('data', params);
  }

  // Action todo (commanded & received from controller)
  private _onAction(params: { req: string; }) {
    return this.onActionSubject.next(params);
  }

  // On data requested from controller, should response emitting new event  
  private _onData(params: any) {
    switch (params.params.res) {
      case 'loaded': {
        this.logger.info("Controller connected!");
        this.qrCodeHandlerService.isControllerConnected = true;
        this.emitData({
          res: 'current-set',
          params: {
            set: this.setService.getActualSet()
          }
        });
        return;
      }
    }

    return this.onDataSubject.next(params);
  }
}
